import React from 'react'
import { Hydrate } from 'react-query/hydration'

import './src/styles/global.css'

import {
    QueryProvider,
    queryClient
} from './src/contexts/query'

import {
    AuthProvider
} from './src/contexts/auth'

import {
    NotificationProvider
} from './src/contexts/notifications'

import isBrowser from './src/validators/isBrowser'

const dehydratedState = isBrowser() && window.__REACT_QUERY_STATE__

export const wrapPageElement = ({ element, props }) => {
    return (
        <QueryProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <NotificationProvider>
                    <AuthProvider props={props}>
                        {element}
                    </AuthProvider>
                </NotificationProvider>
            </Hydrate>
        </QueryProvider>
    )
}