import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import {
    navigate
} from 'gatsby'

import isBrowser from '../validators/isBrowser'

import {
    isEmail,
    isEmpty,
    isLength,
    isStrongPassword,
    trim,
    escape
} from 'validator'

class AuthService {
    constructor() {
        const app = firebase.initializeApp({
            apiKey: process.env.GATSBY_FIREBASE_AUTH_API_KEY,
            authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.GATSBY_FIREBASE_AUTH_PROJECT_ID,
            storageBucket: process.env.GATSBY_FIREBASE_AUTH_STORAGE_BUCKET,
            messagingSenderId: process.env.GATSBY_FIREBASE_AUTH_MESSAGING_SENDER_ID,
            appId: process.env.GATSBY_FIREBASE_AUTH_APP_ID,
            databaseURL:  process.env.GATSBY_FIREBASE_AUTH_DATABASE_URL
        })

        this.app = app
        this.auth = app.auth()
    }
    
    _sanitizeStringField( value ) {
        let payload = value
        
        payload = trim( payload )
        payload = escape( payload )

        return payload
    }

    _validateStringField( value, name ) {
        const min = 2
        const max = 50

        if ( isEmpty( value ) || !isLength( value, { min, max } ) ) {
            throw new Error(`Enter a valid ${name}`)
        }
    }

    _validatePassword( value ) {
        const options = { 
            minLength: 6,
            minLowercase: 0,
            minUppercase: 0,
            minNumbers: 0,
            minSymbols: 0,
        } 

        if ( !isStrongPassword( value, options ) ) {
            throw new Error(`Password must be greater than 6 chartacters`)
        }
    }

    _validateEmail( email ) {
        if ( !isEmail( email ) ) {
            throw new Error(`Enter a valid email`)
        }
    }

    _getAuthProvider() {
        return new firebase.auth.OAuthProvider('google.com')
    }

    isLoggedIn() {
        try {
            const user = this.auth.currentUser

            if (!user) throw new Error()

            return true
        } catch (e) {
            return false
        }
    }

    async login( email, password ) {
        this._validateEmail( email )
        this._validateStringField( password, 'password' )
        
        try {
            await this.auth.signInWithEmailAndPassword( email, password )
        } catch (e) {
            throw new Error(`Invalid login`)
        }
    }

    loginGoogle() {
        const provider = this._getAuthProvider()

        this.app.auth().signInWithRedirect(provider)
    }

    async updateProfile(targetUser, {
        displayName,
        photoUrl
    }) {
        try {
            const options = {}

            if ( !displayName) options.displayName = displayName
            if ( !photoUrl ) options.photoUrl = photoUrl

            await targetUser.updateProfile(options)
        } catch (e) {
            console.log(e)
        }
    }

    async register( firstName, lastName, email, password ) {
        this._validateStringField( firstName, 'first name' )
        this._validateStringField( lastName, 'last name' )
        this._validatePassword( password )
        this._validateEmail( email )

        try {
            const session = await this.auth.createUserWithEmailAndPassword( email, password )

            const targetUser = session.user

            await this.updateProfile(targetUser, {
                displayName: `${firstName} ${lastName}`
            })
        } catch (e) {
            throw new Error(e.message)
        }

        return
    }
    
    async authToken (token) {
        const provider = this._getAuthProvider()
        const credential = provider.credential(token)
        await this.app.auth().signInWithCredential(credential)
    }

    getAuthApp() {
        return this.auth
    }

    async logout() {
        await this.auth.signOut()
        localStorage.clear()
        sessionStorage.clear()

        navigate('/')
    }

    async getToken() {
        try {
            const token = await this.auth.currentUser.getIdToken( true )

            return token
        } catch (e) {
            return false
        }
    }

    getAvatar() {
        try {
            return this.auth.currentUser.photoURL
        } catch (e) {
            return ''
        }
    }

    getMetadataRef( uid ) {
        const ref = `metadata/${uid}/refreshTime`
        const metadataRef = this.app.database().ref(ref)

        return metadataRef
    }

    async sendPasswordResetEmail( email ) {
        this._validateEmail( email )

        await this.auth.sendPasswordResetEmail( email )
    }
}

export const authService = isBrowser() && new AuthService()