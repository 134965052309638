import { QueryClient, QueryClientProvider } from 'react-query'
import React from 'react'

export const queryClient = new QueryClient()

export const QueryProvider = ({
    children
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}