import React, { createContext, useContext, useReducer } from 'react'

const initialState = []

export const store = createContext(initialState)

const { Provider } = store

export const useNotifications = () => {
    return useContext(store)
}

export const NotificationProvider = ({
    children
}) => {
    const [ state, dispatch ] = useReducer((state, actions) => {
        const curState = [ ...state ]

        switch (actions.type) {
            case "ADD_ERROR":
                if (curState.length > 4) break

                curState.unshift(
                    {
                        id: new Date().getTime(),
                        type: 'error',
                        message: `${actions.payload}`
                    }
                )

                break
            case "ADD_SUCCESS":
                if (curState.length > 4) break

                curState.unshift(
                    {
                        id: new Date().getTime(),
                        type: 'success',
                        message: `${actions.payload}`
                    }
                )

                break
            case "REMOVE":
                const targetIndex = curState.findIndex(({ id }) => id === actions.payload)

                curState.splice(targetIndex, 1)

                break
            default:
                break
        }

        return curState
    }, initialState )

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}