import React, { createContext, useContext, useState, useEffect } from 'react'

import isBrowser from '../validators/isBrowser'
import { authService }  from '../services/firebase'

const AuthContext = createContext({})

export const useAuth = () => {
    return useContext(AuthContext)
}

const { Provider } = AuthContext

export const AuthProvider = ({
    children,
}) => {
    const [ authState, setAuthState ] = useState({ status: 'loading' })

    useEffect(() => {
        if ( !isBrowser() ) return

        const unsubscribe = authService.getAuthApp().onAuthStateChanged(async user => {
            if (user) {
                const token = await user.getIdToken()
                const idTokenResult = await user.getIdTokenResult()
                const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims']

                if (hasuraClaim) {
                    setAuthState({ status: 'in', user, token })
                } else {
                    const metadataRef = authService.getMetadataRef( user.uid )
            
                    metadataRef.on('value', async (data) => {
                        if( !data.exists ) return

                        const token = await user.getIdToken(true)

                        setAuthState({ status: 'in', user, token })
                    })
                }
            } else {
                setAuthState({ status: 'out' })
            }
        })

        return unsubscribe
    }, [])

    return (
        <Provider value={{
            authService,
            authState
        }}>
            {children}
        </Provider>
    )
}